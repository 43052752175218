<template>
    <div class="page-app-delete-modal">

        <div class="button-block">
            <button class="btn btn-primary" @click="showDeleteModal">Delete modal</button>
        </div>

        <app-dialog-device-delete-dialog
                v-if="dialogs.delete"
                v-model="dialogs.delete"

                :uuid="selected_device.UUID"

                @close="close"
                @delete-device="deletedevice"
        ></app-dialog-device-delete-dialog>

    </div>
</template>

<script>
import appDialogDeviceDeleteDialog from "@/components/app-dialog-device-delete";

export default {
    components: {
        appDialogDeviceDeleteDialog
    },
    data() {
        return {
            selected_device: null,
            loading: false,
            dialogs: {
                delete: false,
                info: {
                    show: false,
                    device: null,
                },
            },
        }
    },
    methods: {
        close() {
            this.selected_device = null;
            this.dialogs.delete = false;
        },

        showDeleteModal(device) {
            this.selected_device = {
                UUID: 'CUSTOM_DEVICE'
            };

            this.dialogs.delete = true;
        },

        deletedevice(device_id) {

            console.log('DELETE DONE');

            this.loading = true;
            //this.$store
            //    .dispatch("removeDevices", {
            //        id: device_id
            //    })
            //    .then(response => {
            //        console.log("éeee", response);
            //        this.loading = false;
            //        this.close();
            //        this.fetchDevices();
            //    })
            //    .catch(err => {
            //        this.loading = false;
            //    });

            // this.devices = this.devices.filter(device => device.UUID !== device_id);
            this.selected_device = null;
        },
    }
}
</script>

<style lang="scss">
    .page-app-delete-modal {
        padding: 80px 0;

        .button-block{
            display: flex;
            justify-content: space-evenly;
        }

    }
</style>